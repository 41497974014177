
import {computed, defineComponent, onMounted} from "vue";
import HotelSpec from "@/components/hotel/HotelSpec.vue";
import ImageGallery from "@/components/common/ImageGallery.vue";

import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "overview",
  components: {
    HotelSpec,
    ImageGallery,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Fact-Sheet", ["Otel"]);
    });

    const route = useRoute();
    const id = route.params.fcid;
    const hotelID = route.params.id;
    const payload = {
      ID: id,
    }
    //console.log(payload);
    store.dispatch(Actions.HOTEL_FACTSHEETS_DETAILS, payload);
    const myDetails = computed(() => {
      return store.getters.hotelFactSheetsDetails;
    });

    const payload2 = {
      HotelFactSheetID: id,
    }
    store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_LIST, payload2);
    const myItems = computed(() => {
      return store.getters.hotelFactSheetItemsList;
    });

    const payload3 = {
      is_active: "active"
    }
    store.dispatch(Actions.FACTSHEETS_LIST, payload3);
    const myList = computed(() => {
      return store.getters.factSheetsList;
    });

    const payload4 = {
      HotelID: route.params.id
    }
    store.dispatch(Actions.HOTEL_CONCEPTS_LIST, payload4);
    const myConcept = computed(() => {
      return store.getters.hotelConceptsList;
    });

    const payload5 = {
      HotelID: route.params.id
    }
    store.dispatch(Actions.HOTEL_ROOMS_LIST, payload5);
    const myRooms = computed(() => {
      return store.getters.hotelRoomsList;
    });

    const payload6 = {
      ID: route.params.id
    }
    store.dispatch(Actions.HOTEL_DETAILS, payload6);
    const myHotelDetails = computed(() => {
      return store.getters.hotelDetails;
    });

    const insertImage = ()=>{
      const payload4 = {
        Type: "HOTEL-FACT-SHEET",
        ParentID: hotelID,
        ChildID: 'NULL',
        SubChildID: 'NULL',
      }
      store.dispatch(Actions.FILES_SELECTED, payload4);
      store.dispatch(Actions.FILES_LIST, payload4);
    };
    const filteredItems = (ID, Type, RoomID) => {
      if (myItems.value.data != null) {
        return (myItems.value.data).filter(Item =>
            ((ID != null && Item.CategoryID == ID) || ID == null) &&
            ((Type != null && Item.Type == Type) || Type == null) &&
            ((RoomID != null && Item.TypeID == RoomID) || RoomID == null)
        );
      } else {
        return {};
      }
    };
    const filteredList = (Type) => {
      if (myList.value.data != null) {
        return (myList.value.data).filter(ListItem =>
            ListItem.Type == Type
        );
      } else {
        return {};
      }
    };

    return {
      id,
      myDetails,
      myHotelDetails,
      myItems,
      myList,
      hotelID,
      filteredItems,
      filteredList,
      insertImage,
      myRooms,
      myConcept,
    };
  },
});
