
import {computed, defineComponent, ref} from "vue";
import { Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Editor from "@/components/common/TinyEditor.vue";

export default defineComponent({
  name: "new-factsheet-modal",
  components: {
    Editor,
    Form,
  },
  props: {
  },
  computed:{
  },
  watch:{
  },
  setup() {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const updateFactSheetModalRef = ref<null | HTMLElement>(null);

    const mySelectedItem = computed(() => {
      return store.getters.editorContent;
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");


      const payload1 = {
        ID: mySelectedItem.value.ID,
        ExtraInfo: mySelectedItem.value.Content,
        HotelFactSheetID: mySelectedItem.value.HotelFactSheetID,
      }

      store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_EDIT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              //Disable button
              submitButtonRef.value.disabled = false;
              // Activate indicator
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(updateFactSheetModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });


    };

    return {
      mySelectedItem,
      submit,
      submitButtonRef,
      updateFactSheetModalRef,
    };
  },
});
