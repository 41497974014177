<template>
  <div id="app">
    <editor
        v-model="myContent.Content"
        initialValue="<p>Initial editor content</p>"
        apiKey="8xsv3r7vm1rt734yywcvc71rstyd15wnlu7wv0sxxbp8pl1b"

        :init="{
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap',
        'searchreplace visualblocks code fullscreen',
        'print preview anchor insertdatetime media',
        'paste code help wordcount table'
      ],
      toolbar:
        'undo redo | formatselect | bold italic | \
        alignleft aligncenter alignright | \
        bullist numlist outdent indent | help'
    }"
    >
    </editor>

  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import store from "@/store";

export default {
  name: 'app',
  data: function () {
    return {
    }
  },
  computed: {
    myContent (){
        //apiKey="qghte8drjbdxbkntfpn9a9ex8e446hezep485xsq7cp75qyq"
      return store.getters.editorContent;
    },
  },
  components: {
    editor: Editor
  }
}
</script>