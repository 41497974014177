
import {computed, defineComponent} from "vue";

import HotelSpecAdd from "@/components/hotel/HotelSpecAddNew.vue";
import FactSheetItemUpdate from "@/components/hotel/FactSheetItemUpdate.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import InfoTypes from "@/core/data/factsheetitemextra";
import {Actions} from "@/store/enums/StoreEnums";
import ImageGallery from "@/components/common/ImageGallery.vue";

export default defineComponent({
  name: "HotelSpec",
  components: {
    HotelSpecAdd,
    FactSheetItemUpdate,
    ImageGallery,
  },
  props: {
    widgetClasses: String,
    factSheetID: String,
    item: Object,
    itemData: Object,
    hotelID: String,
    fsType: String,
    fsTypeID: String,
    fsTitle: String,
    market: String,
    lang: String,
  },
  data: function () {
    return {
      myCatID: 0,
      myFactsheetID: "0",
      myContentShowStatus: [],
    }
  },
  methods: {
    setCatID(ID) {
      let payload = {
        "ID": ID,
        "HotelID": this.hotelID,
        "FactSheetID": this.factSheetID,
        "Type": this.fsType,
        "TypeID": this.fsTypeID,
      };
      let payload2 = {
        "is_Selected": true,
        "FactSheetID": ID,
        "is_active": 'active',
      };
      store.dispatch(Actions.FACTSHEETS_SELECTED, payload);
      store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_SELECTED, this.itemData);
      store.dispatch(Actions.FACTSHEET_ITEMS_LIST, payload2);
      this.myCatID = ID;
    },
    showContent(ID) {
      this.myContentShowStatus[ID] = (this.myContentShowStatus[ID] == true) ? false : true;
    },
    setItems(Index) {
      let payload = {
        "ID": this.itemData[Index].ID,
        "Title": this.itemData[Index].Title,
        "Content": this.itemData[Index].ExtraInfo,
        "Type": 'FactSheetItem',
        "HotelFactSheetID": this.itemData[Index].HotelFactSheetID,
      };
      store.dispatch(Actions.EDITOR_CONTENT, payload);
    },
    orderSpec(key, Action) {
      const payload = {
        ID: this.itemData[key].ID,
        Action: Action,
        HotelFactSheetID: this.itemData[key].HotelFactSheetID,
        CategoryID: this.itemData[key].CategoryID,
      }
      store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_ORDER, payload)
    },
  },
  setup(props) {
    const updateActive = (ID, Active) => {
      const payload = {
        ID: ID,
        HotelFactSheetID: props.factSheetID,
        is_active: (Active == 'active') ? "passive" : "active",
      }
      store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_EDIT, payload)
    }

    const updateItem = (e) => {
      let myID = "";
      let myExtraInfo = "";
      let loadingID = ""
      let button;
      if (e.target.type == 'select-one') {
        myID = e.target.selectedOptions[0].dataset.id;
        myExtraInfo = e.target.selectedOptions[0].value;
      } else if (e.target.type == 'text') {
        myID = e.target.dataset.id;
        loadingID = "#kt_button_" + myID
        button = document.querySelector(loadingID);
        if (e.keyCode === 13) {
          myExtraInfo = e.target.value;
        } else {
          button.setAttribute("data-kt-indicator", "on");
          return;
        }
      } else {
        return;
      }
      loadingID = "#kt_button_" + myID
      button = document.querySelector(loadingID);
      const payload = {
        ID: myID,
        HotelFactSheetID: props.factSheetID,
        ExtraInfo: myExtraInfo
      }

      button.setAttribute("data-kt-indicator", "on");

      store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_EDIT, payload)
          .then(() => {
            button.removeAttribute("data-kt-indicator");
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getUserErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            button.removeAttribute("data-kt-indicator");
          });
    }

    const deleteItem = (ID) => {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload = {
            ID: ID,
            HotelFactSheetID: props.factSheetID,
          }
          store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_DELETE, payload)
              .then(() => {
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getHotelFactsheetsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });

    }

    const insertImage = ()=>{
      const payload4 = {
        Type: "HOTEL-FACT-SHEET",
        ParentID: props.hotelID,
        ChildID: props.item.ID,
        SubChildID: props.fsTypeID,
      }
      store.dispatch(Actions.FILES_SELECTED, payload4);
      store.dispatch(Actions.FILES_LIST, payload4);
    };
    return {
      InfoTypes,
      updateItem,
      updateActive,
      deleteItem,
      insertImage,
    };
  },
});
