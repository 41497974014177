const infoList = {
  "AVAILABLE" : [
    {
      "Value" : 'AVAILABLE',
      "Title" : 'VAR',
    },
    {
      "Value" : 'NOTAVAILABLE',
      "Title" : 'YOK',
    }],
  "YES" : [
    {
      "Value" : 'YES',
      "Title" : 'EVET',
    },
    {
      "Value" : 'NO',
      "Title" : 'HAYIR',
    }],
  "PAID" : [
    {
      "Value" : 'PAID',
      "Title" : 'ÜCRETLİ',
    },
    {
      "Value" : 'UNPAID',
      "Title" : 'ÜCRETSİZ',
    }]
};
export default infoList;
