export const commonf =  {
    strToDecimal (str) {
        return str.replace(/[^\d.-]/g, '');
    },
    numberFormat (number) {
        return Number(number).toLocaleString('en-US', {maximumFractionDigits:2})
    },
    byteToMb (byte) {
        const rtValue = {
            "value": (((byte / 1024) / 1024).toFixed(3)),
            "unit": "Mb"
        };
        return rtValue;
    },
    decimalCount (num) {
        // Convert to String
        const numStr = String(num);
        // String Contains Decimal
        if (numStr.includes('.')) {
            return numStr.split('.')[1].length;
        };
        // String Does Not Contain Decimal
        return 0;
    },
    inputDecimal (e) {
        const decCount = commonf.decimalCount(e.target.value);
        console.log(decCount)
        if (decCount > 2)e.target.value = Math.floor(e.target.value * 100) / 100
        //if (decCount > 2) e.target.value = Number(e.target.value).toFixed(2)
    },
    creditCardMask (e) {
        return '************'+e.substr(-4)
    },
};