
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "hote-spec-add-new",
  components: {},
  props: {
    catID: Number,
    hotelID: String,
    factSheetID: String,
    market: String,
    lang: String,
    fsType: String,
    fsTypeID: String,
  },
  data: function () {
    return {
      searchName1: '',
    }
  },
  computed: {
    filteredList() {
      if (this.myList1 != null) {
        return this.myList1.filter(item =>
            !(item.Title).toLowerCase().indexOf(this.searchName1.toLowerCase())
        );
      } else {
        return true;
      }
    },
    myList1() {
      return store.getters.selectedFactSheetItemsList;
    }
  },
  setup(props) {
    const myCat = computed(() => {
      return store.getters.selectedFactSheet;
    });
    const mySelectedList = computed(() => {
      return store.getters.selectedHotelFactSheetItems;
    });
    const myList = computed(() => {
      return store.getters.selectedFactSheetItemsList;
    });

    const insertItem = (ID) => {

      const payload2 = {
        HotelFactSheetID: props.factSheetID,
        Market: props.market,
        HotelID: props.hotelID,
        Type: myCat.value.Type,
        TypeID: myCat.value.TypeID,
        Language: props.lang,
        CategoryID: myCat.value.ID,
        ItemID: ID,
        ExtraInfo: "",
      }
      console.log(payload2);

      store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_INSERT, payload2)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              const payload2 = {
                "is_Selected": true,
                HotelFactSheetID: props.factSheetID,
              }
              store.dispatch(Actions.HOTEL_FACTSHEET_ITEMS_LIST, payload2);

            });
            //submitButton2.value?.removeAttribute("data-kt-indicator");
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getUserErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            //submitButton2.value?.removeAttribute("data-kt-indicator");
          });


    };
    const checkArray = (obj, key, attr) => {
      if (mySelectedList.value !== undefined) {
        if(mySelectedList.value.length>0) {
          let check = (mySelectedList.value).filter(Item =>
              Item.ItemID == attr &&
              Item.Type == myCat.value.Type &&
              Item.TypeID == myCat.value.TypeID
          );
          if (check.length > 0) {
            return true
          } else {
            return false
          }
        }else{
          return false;
        }
      } else {
        return false;
      }

    }


    return {
      myList,
      mySelectedList,
      myCat,
      insertItem,
      checkArray,
    };
  },
});
